import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  message
} from 'ant-design-vue'
Vue.use(VueRouter)
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    redirect: '/homemain'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [{
        path: '/homemain',
        name: 'Homemain',
        component: () => import('../views/Homemain'),
        meta: {
          title: '焱十一'
        }
      },
      {
        path: '/auctionhome',
        name: 'Auctionhome',
        component: () => import('../views/Auction/Auctionhome'),
        meta: {
          title: '全球拍卖会',
          breadNumber: 1
        },
        keepAlive: true
      },
      {
        path: '/auctiondetails',
        name: 'Auctiondetails',
        component: () => import('../views/Auction/Browse'),
        meta: {
          title: '拍卖会详情',
          breadNumber: 2
        },
        keepAlive: true
      },
      {
        path: '/auctionhouse',
        name: 'Auctionhouse',
        component: () => import('../views/Auctionhouse/Auctionhouse'),
        meta: {
          title: '拍卖行',
          breadNumber: 3
        }
      },
      {
        path: '/recommendedItemall',
        name: 'RecommendedItemall',
        component: () => import('../views/RecommendedItemall/RecommendedItemall'),
        meta: {
          title: '推荐拍品',
          breadNumber: 4

        }
      },
      {
        path: '/auctionhousedetails',
        name: 'Auctionhousedetails',
        component: () => import('../views/Auctionhouse/Auctionhousedetails'),
        meta: {
          title: '拍卖行详情',
          breadNumber: 5
        }
      },
      {
        path: '/productdetails',
        name: 'Productdetails',
        component: () => import('../components/Productdetails'),
        meta: {
          title: '拍品详情',
          breadNumber: 6
        }
      },
      {
        path: '/recommendedproductdetails',
        name: 'Recommendedproductdetails',
        component: () => import('../views/Productdetails/paipProductdetails.vue'),
        meta: {
          title: '推荐拍品详情',
          breadNumber: 7
        }
      },
      {
        path: '/personalcenter',
        name: 'Personalcenter',
        component: () => import('../views/Personalcenter/Personalcenter'),
        meta: {
          title: '个人中心'
        }
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('../components/test'),
        meta: {
          title: '测试'
        }
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import('../views/search/Search'),
        meta: {
          title: '搜索',
          breadNumber: 8
        },
        keepAlive: true
      },
      {
        path: '/rili',
        name: 'rili',
        component: () => import('../views/Calendar/rili'),
        meta: {
          title: '日历'
        }
      },
      {
        path: '/overlay',
        name: 'Overlay',
        component: () => import('../views/Gooverlay/Gooverlay'),
        meta: {
          title: '分类',
          breadNumber: 9
        },
        keepAlive: true
      },
      {
        path: '/termagreement',
        name: 'Termagreement',
        component: () => import('../views/Termagreement/Termagreement'),
        meta: {
          title: '条款与协议',
          breadNumber: 10
        }
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../views/Termagreement/Privacy'),
        meta: {
          title: '隐私政策',
          breadNumber: 11
        }
      },
      {
        path: '/servicerule',
        name: 'Servicerule',
        component: () => import('../views/Termagreement/Servicerule'),
        meta: {
          title: '隐私政策',
          breadNumber: 13
        }
      },
      {
        path: '/helpmiddle',
        name: 'Helpmiddle',
        component: () => import('../views/Termagreement/Helpmiddle'),
        meta: {
          title: '帮助中心',
          breadNumber: 12
        }
      },
      {
        path: '/aboutus',
        name: 'Aboutus',
        component: () => import('../views/Termagreement/Aboutus'),
        meta: {
          title: '关于我们',
          breadNumber: 14
        }
      },
      {
        path: '/contactus',
        name: 'Contactus',
        component: () => import('../views/Termagreement/Contactus'),
        meta: {
          title: '联系我们',
          breadNumber: 15
        }
      },
      {
        path: '/Useguide',
        name: 'Useguide',
        component: () => import('../views/Termagreement/Useguide'),
        meta: {
          title: '使用指南'
        }
      },
      {
        path: '/auctionhousecheckin',
        name: 'Auctionhousecheckin',
        component: () => import('../views/Auctionhousecheckin/Auctionhousecheckin'),
        meta: {
          title: '拍卖行入驻'
        }
      },
      {
        path: '/auctionpersonalcenter',
        name: 'Auctionpersonalcenter',
        component: () => import('../views/Auctionpersonalcenter/Auctionpersonalcenter'),
        meta: {
          title: '个人中心'
        }
      },
      {
        path: '/forgetthepassword',
        name: 'ForgetThePassword',
        component: () => import('../components/ForgetThePassword'),
        meta: {
          title: '忘记密码'
        }
      },
      {
        path: '/forgetthepasswordShop',
        name: 'ForgetThePasswordShop',
        component: () => import('../components/ForgetThePasswordShop'),
        meta: {
          title: '忘记密码'
        }
      },
      {
        path: '/previewlot',
        name: 'Previewlot',
        component: () => import('../views/Auctionpersonalcenter/Previewlot/Previewlot'),
        meta: {
          title: '查看拍品'
        }
      },
      {
        path: '/liveauctionhouse',
        name: 'Liveauctionhouse',
        component: () => import('../views/Auctionpersonalcenter/Liveauctionhouse/Liveauctionhouse'),
        meta: {
          title: '直播控制台'

        }
      },
      {
        path: '/buyerlive',
        name: 'Buyerlive',
        component: () => import('../views/Auctionpersonalcenter/Buyerlive/Buyerlive'),
        meta: {
          title: '拍卖直播'
        }
      },
      {
        path: '/AllLiveauction',
        name: 'AllLiveauction',
        component: () => import('../views/AllLiveauction/AllLiveauction'),
        meta: {
          title: '正在直播'
        }
      },
      {
        path: '/businessesIn',
        name: 'businessesIn',
        component: () => import('../views/businessesIn/businessesIn'),
        meta: {
          title: '商家入驻'
        }
      },
      {
        path: '/mallBusinessCenter',
        name: 'mallBusinessCenter',
        component: () => import('../views/mallBusinessCenter/mallBusinessCenter'),
        meta: {
          title: '个人中心'
        }
      },
      {
        path: '/liveFor',
        name: 'liveFor',
        component: () => import('../views/liveFor/liveFor'),
        meta: {
          title: '直播申请'
        }
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决跳转下一个页面底部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const role = localStorage.getItem('accessToken')
  if ((!role && to.path === '/personalcenter') || (!role && to.path === '/auctionpersonalcenter') || (!role && to
      .path === '/previewlot') || (!role && to.path === '/liveauctionhouse')) {
    next('/homemain')
    // message.error('请您先登录')
  } else {
    next()
  }
  var routeList = []
  var index = -1
  //   判断路由之前是否存在，并返回位置
  for (var i = 0; routeList.length; i++) {
    if (routeList[i].name === to.name) {
      index = i
      break
    }
  }
  if (index !== -1) {
    //   如果存在，则回到重复路由的之前位置
    routeList.splice(index + 1, routeList.length - index - 1)
  } else {
    //   不存在则加入循环数组内 （注1）
    routeList.push({
      name: to.name,
      path: to.path
    })
  }
  to.meta.routeList = routeList
  next()
})
router.onError((error) => {
  const pattern = /Loading chunk .* failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    location.reload()
  }
})
export default router
