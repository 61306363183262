<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
  </div>
</template>
<script>
  export default {
    name: 'App',
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isShow: true
      }
    },
    created() {
      this.isMobile()
      this.refresh()
    },
    methods: {
      isMobile() { //根据设备跳转移动端（只做了正式环境）
        if ((navigator.userAgent.match( //平板电脑不跳转
            /(pad|iPad)/i
          ))) {
          return
        }
        if ((navigator.userAgent.match( //其他移动端设备跳转移动端页面
            /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          ))) {
          const url = window.location.href //获取当前url
          if (url.startsWith('https://www.easyebid.com/index.html#/auctiondetails?')) { //拍卖会详情
            const id = this.getUrlParam(url, 'auctiondetailsId')
            window.location.href = `https://wh5.easyebid.com/index.html#/pages/auctionDetails/auctionDetails?id=${id}`
          } else if (url.startsWith('https://www.easyebid.com/index.html#/productdetails?')) { //拍品详情
            const id = this.getUrlParam(url, 'productdetailsId')
            window.location.href = `https://wh5.easyebid.com/index.html#/pages/goodsDetails/goodsDetails?id=${id}`
          } else if (url.startsWith('https://www.easyebid.com/index.html#/auctionhousedetails?')) { //拍卖行详情
            const id = this.getUrlParam(url, 'auctionhousedetailsid')
            window.location.href =
              `https://wh5.easyebid.com/index.html#/pages/auctionhouseDetails/auctionhouseDetails?id=${id}`
          } else if (url == 'https://www.easyebid.com/index.html#/Useguide') { //视频使用指南
            window.location.href = 'https://wh5.easyebid.com/index.html#/pages/useguide/useguide'
          } else { //其他页面跳主页
            window.location.href = "https://wh5.easyebid.com";
          }
        }
      },
      getUrlParam(url, paraName) { //获取url上的拼接参数
        const arrObj = url.split("?");
        if (arrObj.length > 1) {
          const arrPara = arrObj[1].split("&");
          let arr;
          for (let i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split("=");
            if (arr != null && arr[0] == paraName) {
              return arr[1];
            }
          }
          return "";
        } else {
          return "";
        }
      },
      // 全局刷新
      reload() {
        this.isShow = false
        this.$nextTick(() => {
          this.isShow = true
        })
      },
      // 防止vuex刷新数据丢失
      refresh() {
        if (sessionStorage.getItem('store')) {
          this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
        }
        window.addEventListener('beforeunload', () => {
          sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
      }

    }
  }
</script>
<style lang="less">
  #app {
    font-family: sans-serif, LisztFY-Rg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333;
  }

  /deep/ .el-button--primary {
    background: #cda156 !important;
    border: 1px solid #cda156 !important;
  }

  /deep/ .ant-pagination-item {
    font-size: 17px;
  }

  .viewer-canvas {
    >.viewer-transition {
      transition: all .01s !important;
    }
  }
</style>
