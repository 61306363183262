import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import 'normalize.css'
import './style/style.css'
import Antd, {
  message
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '../src/assets/fonts/iconfont.css'
import 'moment/locale/zh-cn' // 中文插件
import VueI18n from 'vue-i18n'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
import ElementUI, {
  MessageBox
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueResource from 'vue-resource'
import md5 from 'js-md5'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Print from '../public/pulags/vuePlugs_printjs-master/print'
// import { Base64 } from 'js-base64'
import moment from 'moment'
Vue.prototype.$moment = moment

Vue.use(Print) // 注册
Vue.use(VueResource)
// Vue.prototype.$Base64 = Base64
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
  },
  url: 'data-source'
})
Vue.use(Viewer)
Vue.prototype.$md5 = md5
Vue.use(ElementUI)
Vue.use(VueDirectiveImagePreviewer)
Vue.use(VueI18n)
Vue.use(Antd)
message.config({
  top: '50%',
  duration: 2,
  maxCount: 1
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
